import { Link } from '@tiptap/extension-link'
import { Plugin, PluginKey } from 'prosemirror-state'
import { type EditorView } from 'prosemirror-view'

interface CustomLinkOptions {
    openOnClick: boolean
    // Add any other custom options here
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        customLink: {
            /**
             * Set a link on the selected text
             */
            setLink: (attributes: { href: string; target?: string }) => ReturnType
            /**
             * Unset a link
             */
            unsetLink: () => ReturnType
        }
    }
}

const CustomLink = Link.extend<CustomLinkOptions>({
    addOptions() {
        return {
            ...this.parent?.(),
            openOnClick: false,
        }
    },

    addAttributes() {
        return {
            ...this.parent?.(),
            target: {
                default: null,
            },
        }
    },

    addProseMirrorPlugins() {
        const plugins = this.parent?.() || []

        return [
            ...plugins,
            new Plugin({
                key: new PluginKey('customLinkClick'),
                props: {
                    handleClick(view: EditorView, pos: number, event: MouseEvent) {
                        const { state } = view
                        const node = state.doc.nodeAt(pos)

                        if (node && node.type.name === 'text' && node.marks.find(mark => mark.type.name === 'link')) {
                            const linkMark = node.marks.find(mark => mark.type.name === 'link')
                            const href = linkMark?.attrs.href

                            if ((event.metaKey || event.ctrlKey) && href) {
                                event.preventDefault()
                                window.open(href, '_blank', 'noopener,noreferrer')
                                return true // Indicates that the event was handled
                            }
                        }

                        return false // Allows other plugins to handle the click if not handled here
                    },
                },
            }),
        ]
    },
})

export default CustomLink