import Highlight from '@tiptap/extension-highlight'
import Placeholder from '@tiptap/extension-placeholder'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Typography from '@tiptap/extension-typography'
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Bold, Code, Heading1, Heading2, Heading3, Italic, Link2, List, ListOrdered, ListTodo, Quote, Strikethrough } from 'lucide-react'
import { forwardRef } from 'react'
import { Button } from '../catalyst/button'
import CustomLink from './custom-link'

interface TiptapProps {
    content?: string
    placeholder?: string
    className?: string
    onContentChange?: (content: string) => void
    onKeyDown?: (event: KeyboardEvent) => boolean
    onFocus?: (event: FocusEvent) => void
    onBlur?: (event: FocusEvent) => void
}

const Tiptap = forwardRef<HTMLDivElement, TiptapProps>(({
    content,
    placeholder,
    className,
    onContentChange,
    onKeyDown,
    onFocus,
    onBlur,
    ...props
}, ref) => {
    const editor = useEditor({
        immediatelyRender: false,
        extensions: [
            // Document,
            // Paragraph,
            // Text,
            StarterKit,
            Highlight.configure({ multicolor: true }),
            Typography,
            Placeholder.configure({
                placeholder: placeholder || 'Write here...',
            }),
            // Link.configure({
            //     openOnClick: true,
            //     autolink: true,
            //     defaultProtocol: 'https',
            // }),
            TaskList,
            TaskItem.configure({
                nested: true,
            }),
            CustomLink,
        ],
        content,
        onUpdate: ({ editor }) => {
            const html = editor.getHTML();
            onContentChange?.(html)
        },
        onFocus: ({ event }) => onFocus?.(event),
        onBlur: ({ event }) => onBlur?.(event),
        editorProps: {
            handleKeyDown: (_, event) => {
                if (onKeyDown) {
                    return onKeyDown(event)
                }
                return false
            },
        },
    })

    const promptLink = async (existingHref: string | null | undefined) => {
        const linkUrl = await prompt('Enter URL', existingHref ?? '')

        if (!linkUrl || linkUrl.length === 0) return;

        let href = /^https?:\/\//.test(linkUrl) ? linkUrl : `https://${linkUrl}`
        try {
            new URL(href) // if not valid url, this will throw
            editor?.chain().focus().setLink({ href }).run()
        } catch { }
    }

    return (
        <>
            <EditorContent
                ref={ref}
                editor={editor}
                className={`${className || ''} focus-visible:outline-none`} {...props}
            />
            {editor &&
                <BubbleMenu
                    editor={editor}
                    tippyOptions={{ duration: 100 }}
                    shouldShow={({ editor }) => {
                        if (editor.isActive('horizontalRule')) return false
                        return editor.state.selection.content().size > 0;
                    }}
                >
                    <div className='flex flex-col rounded-md p-1 border border-black/20 bg-white'>
                        <div className='flex items-center gap-1 pb-1'>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleHighlight({ color: '#fde047' }).run()}
                            >
                                <div className='w-5 h-5 rounded-md bg-yellow-300'></div>
                            </Button>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleHighlight({ color: '#86efac' }).run()}
                            >
                                <div className='w-5 h-5 rounded-md bg-green-300'></div>
                            </Button>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleHighlight({ color: '#f9a8d4' }).run()}
                            >
                                <div className='w-5 h-5 rounded-md bg-pink-300'></div>
                            </Button>
                            <div className='w-px h-6 bg-transparent'></div>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleHighlight({ color: '#93c5fd' }).run()}
                            >
                                <div className='w-5 h-5 rounded-md bg-blue-300'></div>
                            </Button>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleHighlight({ color: '#fdba74' }).run()}
                            >
                                <div className='w-5 h-5 rounded-md bg-orange-300'></div>
                            </Button>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleHighlight({ color: '#d8b4fe' }).run()}
                            >
                                <div className='w-5 h-5 rounded-md bg-purple-300'></div>
                            </Button>
                        </div>
                        <div className='w-full h-px bg-border'></div>
                        <div className='flex items-center gap-1 pb-1'>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                            >
                                <Heading1 size={14} />
                            </Button>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                            >
                                <Heading2 size={14} />
                            </Button>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                            >
                                <Heading3 size={14} />
                            </Button>
                            <div className='w-px h-6 bg-border'></div>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleBulletList().run()}
                            >
                                <List size={14} />
                            </Button>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                            >
                                <ListOrdered size={14} />
                            </Button>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleTaskList().run()}
                            >
                                <ListTodo size={14} />
                            </Button>
                        </div>
                        <div className='w-full h-px bg-border'></div>
                        <div className='flex items-center gap-1 pt-1'>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleBold().run()}
                            >
                                <Bold size={14} />
                            </Button>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleItalic().run()}
                            >
                                <Italic size={14} />
                            </Button>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleStrike().run()}
                            >
                                <Strikethrough size={14} />
                            </Button>
                            <div className='w-px h-6 bg-border'></div>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => {
                                    const existingHref = editor.getAttributes('link').href
                                    promptLink(existingHref).catch(console.error)
                                }}
                            >
                                <Link2 size={14} />
                            </Button>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                            >
                                <Quote size={14} />
                            </Button>
                            <Button
                                plain
                                className='h-8 w-8 !p-0'
                                onClick={() => editor.chain().focus().toggleCode().run()}
                            >
                                <Code size={14} />
                            </Button>
                        </div>
                    </div>
                </BubbleMenu>}
        </>
    )
})

Tiptap.displayName = 'Tiptap'

export default Tiptap

export const isOriginatedFromTiptap = (event: KeyboardEvent) => {
    return event.target instanceof HTMLElement && event.target.getAttribute('class')?.includes('tiptap')
}